import styled from 'styled-components';

export const News = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;

  & > li {
    box-sizing: border-box;
    flex: 33.3%;
    padding: 1rem;
  }
`;

export const Highlights = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, 320px);
  grid-auto-rows: 320px;
  grid-template-areas: 'area1' 'area2' 'area3';
  grid-gap: 0.5rem;
  list-style: none;
  margin: 0;
  transition: transform 0.25s ease-in-out;

  li {
    width: 100%;
    height: 100%;
    overflow: hidden;

    figure {
      transition: transform 0.7s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .highlight__main {
    grid-area: area1;
  }

  .highlight__2 {
    grid-area: area2;
  }

  .highlight__3 {
    grid-area: area3;
  }

  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'area1 area1' 'area1 area1' 'area2 area3';
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 320px);
    grid-template-areas: 'area1 area1 area2 area2' 'area1 area1 area3 area3';
  }
`;
