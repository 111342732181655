import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 14px;
  max-width: ${({ expanded }) => (expanded ? '100%' : '30px')};
  transition: max-width 500ms;
`;

export const IconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text_on_primary};
`;

export const TextContainer = styled.span`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px 0 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text_on_primary};
  white-space: nowrap;
  overflow: hidden;
`;

export default {
  Container,
  IconContainer,
  TextContainer,
};
