import React from 'react';
import styled from 'styled-components';
import ConditionalRenderer from '../conditionalRenderer';
import { Container, IconContainer, TextContainer } from './styles';

const ContainerStyled = styled(Container)`
  z-index: 1;
`;

export default function AnimatedTag(props) {
  const { icon, text, expanded } = props;

  return (
    <ContainerStyled expanded={expanded}>
      <ConditionalRenderer
        condition={expanded}
        renderOnSuccess={() => <TextContainer>{text}</TextContainer>}
      />
      <IconContainer>{icon}</IconContainer>
    </ContainerStyled>
  );
}
