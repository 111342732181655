import React from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import MosaicBlock from 'ground-web/components/v1/MosaicBlock';
import Mosaic from 'ground-web/components/v1/Mosaic';
import Carousel from 'ground-web/components/v1/Carousel';
import Card from '../components/card';
import { decodeHtml, removeHtmlTags } from '.';
import Placeholder from '../images/placeholderNews2x.png';

const MosaicDesktop = styled.div`
  @media screen and (max-width: 780px) {
    display: none;
  }
`;

const MosaicMobile = styled.div`
  @media screen and (min-width: 780px) {
    display: none;
  }
`;

export const parseData = (data) =>
  data.map((node) => {
    const {
      postData: { highlight, redirectLink, exclusive },
    } = node;
    const title = highlight || node.title;
    const categories = node.categories.nodes.filter(
      (category) => category.name.trim().toLowerCase() !== 'destaques',
    );

    return {
      title: decodeHtml(removeHtmlTags(title)),
      description: decodeHtml(removeHtmlTags(node.excerpt)),
      date: node.date,
      image: node.featuredImage?.node?.sourceUrl || Placeholder,
      tag: categories[0]?.name || 'Últimas',
      redirectTo: `/noticias/${node.slug}`, // deprecated
      to: `/noticias/${node.slug}`,
      href: redirectLink,
      id: node.slug,
      hidden: false,
      exclusive,
    };
  });

export function parseFeatured(featuredPost) {
  const { post, image, linkType, link, hideTitle, altTitle } = featuredPost;

  const featuredTitle =
    altTitle?.trim().length > 0 ? decodeHtml(removeHtmlTags(altTitle)) : '';

  if (linkType === 'external') {
    return {
      title: hideTitle === true ? '' : featuredTitle,
      date: '',
      image: image?.sourceUrl || Placeholder,
      tag: '',
      href: link,
      id: link,
    };
  }

  const title = featuredTitle || post.postData.highlight || post.title;
  const categories = post.categories.nodes.filter(
    (category) => category.name.trim().toLowerCase() !== 'destaques',
  );

  return {
    title: hideTitle === true ? '' : decodeHtml(removeHtmlTags(title)),
    date: post.date,
    image:
      image?.sourceUrl || post.featuredImage?.node?.sourceUrl || Placeholder,
    tag: categories[0]?.name || 'Últimas',
    to: `/noticias/${post.slug}`,
    href: post.postData.redirectLink,
    id: post.slug,
    exclusive: post.postData.exclusive,
  };
}

export const renderCards = (parsedNews) =>
  parsedNews.map((news) => (
    <Card to={news.redirectTo} width="255px" key={news.wordpress_id}>
      <figure>
        <img src={news.image} alt="" />
      </figure>
      <div className="tag">{news.tag}</div>
      <h1>{news.title}</h1>
      <small>
        <Moment locale="pt" format="DD/MM/YYYY">
          {news.date}
        </Moment>
        ,{' '}
        <Moment locale="pt" fromNow>
          {news.date}
        </Moment>
      </small>
    </Card>
  ));

export const renderMosaic = (data) => {
  const newsList = data.allWordpressPost.edges;
  const stickyNewsList = newsList.filter((news) => news.node.sticky);
  const stickyNewsData = parseData(stickyNewsList).slice(0, 3);

  return [
    <MosaicMobile>
      <Carousel
        Component={MosaicBlock}
        data={stickyNewsData}
        responsive={() => null}
        showArrows={false}
        autoSlide={6000}
        customDots
      />
    </MosaicMobile>,
    <MosaicDesktop>
      <Mosaic data={stickyNewsData} mode="column" />
    </MosaicDesktop>,
  ];
};
