import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useModal } from '../hooks';
import SignInContainer from './signInContainer';
import ConditionalRenderer from './conditionalRenderer';
import AnimatedTag from './animated-tag';
import Texture from '../images/asfalt-light.png';
import Shield from '../images/escudo.svg';
import { useAuth } from '../context';

export const BaseCard = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  border-radius: 4px;
  line-height: 1.5em;
  text-decoration: none;
  padding: 1rem;
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.text.getSize('cards-title')};
  font-weight: 700;
  line-height: 1.2em;
  min-height: 3.6em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 250ms ease;
`;

export const Tag = styled.span`
  color: ${({ theme }) => theme.colors.primary_dark};
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Caption = styled.small`
  font-size: 0.8rem;
  opacity: 0.7;
  line-height: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
`;

export const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Thumb = styled.figure`
  margin: 0;
  padding: 0;
  padding-bottom: 60%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  transition: all 700ms ease;

  &:first-child {
    margin: -1rem -1rem 1.2rem;
  }
`;

Thumb.propTypes = {
  src: PropTypes.string.isRequired,
};

const ResponsiveContainer = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    ${Title} {
      color: ${({ theme }) => theme.color.primary_dark};
    }
  }
`;

export function ResponsiveCard(props) {
  const { href, to, children } = props;

  if (href && href.trim().length > 0) {
    return (
      <ResponsiveContainer
        as="a"
        href={href}
        {...props}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </ResponsiveContainer>
    );
  }

  if (to && to.trim().length > 0) {
    return (
      <ResponsiveContainer as={Link} to={to} {...props}>
        {children}
      </ResponsiveContainer>
    );
  }

  return <ResponsiveContainer {...props}>{children}</ResponsiveContainer>;
}

ResponsiveCard.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const ImageContainer = styled(ResponsiveContainer)`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  justify-content: flex-end;

  ${Thumb} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }

  ${Thumb}::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 10%, #000000 100%);
    content: "";
  }

  ${Caption}, ${Tag}, ${Title} {
    z-index: 1;
  }

  ${Title} {
    font-family: "Bebas Neue";
    font-size: ${({ theme }) => theme.text.getSize('cards-title')};
    min-height: unset;
  }

  &:hover {
    ${Title} {
      color: ${({ theme }) => theme.colors.secondary_light};
    }
  }

  ${Tag} {
    color: ${({ theme }) => theme.colors.text_on_primary};
    background: ${({ theme }) => theme.colors.primary};
    padding: 0 0.5rem;
    width: max-content;
    height: 1.5rem;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    margin: 0.5rem 0;
  }
`;

export function ImageCard(props) {
  const { href, to, children } = props;

  if (href && href.trim().length > 0) {
    return (
      <ImageContainer
        as="a"
        href={href}
        {...props}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </ImageContainer>
    );
  }

  if (to && to.trim().length > 0) {
    return (
      <ImageContainer as={Link} to={to} {...props}>
        {children}
      </ImageContainer>
    );
  }

  return <ImageContainer {...props}>{children}</ImageContainer>;
}

export const NewsCard = styled(ResponsiveCard)`
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.35);
  width: 100%;
  height: auto;
  min-width: 320px;

  ${Title} {
    font-size: ${({ theme }) => theme.text.getSize('news')};
  }
`;
const Icon = () => <FontAwesomeIcon icon={faLock} width="24" height="24" />;

function RenderNewsCardFn({ item }) {
  const { onOpen, onClose, isOpen } = useModal();
  const { isLoggedIn } = useAuth();
  const [hover, setHover] = useState(false);
  const { title, date, image, tag, to, href, exclusive } = item;

  return (
    <>
      <NewsCard
        to={to}
        href={href}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          if (exclusive) {
            e.preventDefault();
            if (isLoggedIn) {
              navigate(to);
            } else {
              onOpen();
            }
          }
        }}
      >
        <SignInContainer
          redirectTo={to}
          onOpen={onOpen}
          onClose={() => {
            if (isLoggedIn) {
              navigate(to);
            }
            onClose();
          }}
          isOpen={isOpen}
        />
        <Thumb src={image} />
        <Tag>{tag}</Tag>
        <Title>{title}</Title>

        <Caption>
          <Moment locale="pt" format="DD/MM/YYYY">
            {date}
          </Moment>
          <ConditionalRenderer
            condition={exclusive}
            renderOnSuccess={() => (
              <AnimatedTag
                text="Exclusivo para sócios"
                icon={<Icon />}
                expanded={hover}
              />
            )}
          />
        </Caption>
      </NewsCard>
    </>
  );
}

export const RenderNewsCard = React.memo(RenderNewsCardFn);

export const HighlightCard = styled(ImageCard)`
  width: 100%;
  height: 100%;

  ${Title} {
    font-weight: normal;
    font-size: ${({ theme }) => theme.text.getSize('highlights')};
  }
`;

const HighlightBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HighlightTop = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 1rem;
  right: 1rem;
  left: 1rem;
`;

function RenderHighlightFn({ item }) {
  const { title, date, image, to, href, exclusive } = item;
  const [hover, setHover] = useState(false);
  const { isLoggedIn } = useAuth();
  const { isOpen, onClose, onOpen } = useModal();
  return (
    <>
      <HighlightCard
        to={to}
        href={href}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          if (exclusive) {
            e.preventDefault();
            if (isLoggedIn) {
              navigate(to);
            } else {
              onOpen();
            }
          }
        }}
      >
        <HighlightTop>
          <ConditionalRenderer
            condition={exclusive}
            renderOnSuccess={() => (
              <AnimatedTag
                text="Exclusivo para sócios"
                icon={<Icon />}
                expanded={hover}
              />
            )}
          />
        </HighlightTop>
        <Thumb src={image} />
        <Title>{title}</Title>
        <HighlightBottom>
          <ConditionalRenderer
            condition={!!date}
            renderOnSuccess={() => (
              <Caption>
                <Moment locale="pt" format="DD/MM/YYYY">
                  {date}
                </Moment>
              </Caption>
            )}
          />
        </HighlightBottom>
      </HighlightCard>
      <SignInContainer
        redirectTo={to}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      />
    </>
  );
}

export const RenderHighlight = React.memo(RenderHighlightFn);

export const AvatarContainer = styled.figure.attrs((props) => ({
  src: props.src
    ? props.src
    : 'https://freeiconshop.com/wp-content/uploads/edd/person-outline.png',
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  text-align: center;
  figcaption {
    * {
      display: block;
    }
    *:first-child {
      font-weight: bold;
    }
  }
`;

export const Avatar = styled.img.attrs((props) => ({
  src: props.src ? props.src : Shield,
}))`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin: 0;
  margin-bottom: 1rem;
`;

export const AvatarCard = styled(ResponsiveCard)`
  width: 17rem;
  background-color: ${({ theme }) => theme.colors.primary_dark};
  background-image: url(${Texture});
  color: white;
  p {
    min-height: 3rem;
  }
  ${AvatarContainer}, p {
    text-align: center;
    padding: 1rem;
    margin: 0;
  }
  ${Title} {
    min-height: 2.4em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    margin: 0;
  }
  &:hover {
    ${Title} {
      color: unset;
      opacity: 0.8;
    }
  }
  ${Description} {
    padding: 0;
  }
`;
